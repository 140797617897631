import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'betModel'
})
export class BetModelPipe implements PipeTransform {

  transform(value: any): any {
    let bet_model: string;

    switch (value) {
      case '1.000': bet_model = '元'; break;
      case '0.100': bet_model = '角'; break;
      case '0.010': bet_model = '分'; break;
      case '0.001': bet_model = '厘'; break;
    }
    return bet_model;
  }

}
