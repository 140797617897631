import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/providers/api/api.service';
import { NzModalService } from 'ng-zorro-antd';

@Component({
  selector: 'app-detail-page-bet',
  templateUrl: './detail-page-bet.component.html',
  styleUrls: ['./detail-page-bet.component.scss']
})
export class DetailPageBetComponent implements OnInit {

  public token: string;
  public user_id: number;

  public bet_id: string;
  public trace_id: string;
  public game: string;
  public play: string;
  public multiple: string;
  public position: string;
  public lottery: string;
  public bet_content: string;
  public bet_time: string;
  public open_prize: string;
  public ip: string;

  public bet_code: string;
  public username: string;
  public bet_model: string;
  public bet_source: string;
  public up_line: string;
  public single_amount: string;
  public cancel_time: string;
  public create_time: string;
  public proxy_ip: string;
  public prize_status: string;

  public is_tester: string;
  public issue: string;
  public bet_value: string;
  public bet_status: string;
  public open_status: string;
  public cal_time: string;
  public update_time: string;
  public manager: string;


  constructor(
    public api: ApiService,
    public modalService: NzModalService
  ) {
    if (localStorage.getItem('token')) {
      this.token = localStorage.getItem('token');
    }

    this.user_id = Number(window.location.pathname.split('/', 4).pop());
  }

  ngOnInit() {
    this.api.get_bet_detail_info(this.token, this.user_id).subscribe(data => {
      if (data && data['isSuccess']) {
        let info = data['data'];
        this.bet_id = info['id'];
        this.trace_id = info['trace_id'];
        this.game = info['game'];
        this.play = info['play'];
        this.multiple = info['multiple'];
        this.position = info['position'];
        this.lottery = info['lottery'].join(',');
        this.bet_content = info['bet_content'];
        this.bet_time = info['bet_time'];
        this.open_prize = info['send_prize_time'];
        this.ip = info['ip'];
        this.bet_code = info['bet_code'];
        this.username = info['username'];
        this.bet_model = info['coefficient'];
        this.bet_source = info['bet_source'] === 'app' ? 'App' : '网站';
        this.up_line = info['is_gt_max_prize'] === 0 ? '未超限' : '已超限';
        this.single_amount = info['single_amount'];
        this.cancel_time = info['cancel_time'];
        this.create_time = info['create_time'];
        this.proxy_ip = info['proxy_ip'];
        this.issue = info['issue'];
        this.bet_value = info['bet_value'];
        this.bet_status = info['bet_status'];
        this.open_status = info['open_status'];
        this.cal_time = info['calculate_time'];
        this.update_time = info['update_time'];
        this.manager = info['manager'];
        this.is_tester = info['is_tester'] === 0 ? '否' : '是';
        this.prize_status = info['prize_status'] === 0 ? '派奖未完成' : '派奖已完成';

      } else {
        // 错误提示
        let error = data['data']['error'];
        this.modalService.error({
          nzTitle: '温馨提示',
          nzContent: error
        });
      }
    })
  }

}
