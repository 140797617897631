import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/providers/api/api.service';
import { NzModalService, NzMessageService } from 'ng-zorro-antd';

interface ItemData {
  lottery_id: string;
  profit_rate: string;
  number_count: string;
  max_amount: string;
  nickname: string;
}

@Component({
  selector: 'app-jackpot-record',
  templateUrl: './jackpot-record.component.html',
  styleUrls: ['./jackpot-record.component.scss']
})
export class JackpotRecordComponent implements OnInit {
  public editCache: { [key: string]: { edit: boolean; data: ItemData } } = {};
  public is_edit = false;
  public lottery_data: ItemData[] = [];

  public nickname: string;
  public profit_rate: string;
  public number_count: string;
  public max_amount: string;

  public loading = false;
  public token: string;

  constructor(
    public api: ApiService,
    public modalService: NzModalService,
    private message: NzMessageService
  ) { }

  ngOnInit() {
    if (localStorage.getItem('token')) {
      this.token = localStorage.getItem('token');
    }

    this.get_lottery_info_handler();
  }

  /**
   * 获取彩种信息
   *
   * @memberof JackpotRecordComponent
   */
  public get_lottery_info_handler() {
    this.loading = true;

    this.api.get_lottery_odds_handler(this.token).subscribe(data => {
      if (data && data['isSuccess']) {
        this.loading = false;

        let list = data['data'];
        this.lottery_data = list;

        this.updateEditCache();

      } else {
        // 错误提示
        let error = data['data']['error'];
        this.modalService.error({
          nzTitle: '温馨提示',
          nzContent: error
        });
      };
    })
  }

  /**
   * 编辑
   *
   * @param {string} id
   * @memberof JackpotRecordComponent
   */
  public startEdit(id: string): void {
    this.editCache[id].edit = true;
  }

  /**
   *  取消
   *
   * @param {string} id
   * @memberof JackpotRecordComponent
   */
  public cancelEdit(id: string): void {
    const index = this.lottery_data.findIndex(item => item.lottery_id === id);
    this.editCache[id] = {
      data: { ...this.lottery_data[index] },
      edit: false
    };
  }

  /**
   * 保存修改
   *
   * @param {string} id
   * @memberof JackpotRecordComponent
   */
  public saveEdit(data: any): void {
    let id = data.lottery_id;
    const index = this.lottery_data.findIndex(item => item.lottery_id === id);
    Object.assign(this.lottery_data[index], this.editCache[id].data);
    this.editCache[id].edit = false;

    this.api.get_set_lottery_jackpot_handler(
      this.token,
      id,
      this.editCache[data.lottery_id].data.nickname,
      this.editCache[data.lottery_id].data.profit_rate,
      this.editCache[data.lottery_id].data.number_count,
      this.editCache[data.lottery_id].data.max_amount
      // tslint:disable-next-line:no-shadowed-variable
    ).subscribe(data => {
      if (data && data['isSuccess']) {
        this.message.create('success', `修改成功！`);

        this.get_lottery_info_handler();
      } else {
        // 错误提示
        let error = data['data']['error'];
        this.modalService.error({
          nzTitle: '温馨提示',
          nzContent: error
        });
      };
    });
  }

  /**
   * 初始化状态
   *
   * @memberof JackpotRecordComponent
   */
  public updateEditCache(): void {
    this.lottery_data.forEach(item => {
      this.editCache[item['lottery_id']] = {
        edit: false,
        data: { ...item }
      };
    });
  }
}
