import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'betStatus'
})
export class BetStatusPipe implements PipeTransform {

  transform(value: any): any {
    let status: string;
    switch (value) {
      case 0: status = '待开奖'; break;
      case 1: status = '已撤单'; break;
      case 2: status = '未中奖'; break;
      case 3: status = '已中奖'; break;
      case 4: status = '已派奖'; break;
      case 5: status = '系统撤单'; break;
      default: break;
    }
    return status;
  }

}
