import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/providers/api/api.service';
import { NzModalService } from 'ng-zorro-antd';

@Component({
  selector: 'app-detail-page-transaction',
  templateUrl: './detail-page-transaction.component.html',
  styleUrls: ['./detail-page-transaction.component.scss']
})
export class DetailPageTransactionComponent implements OnInit {

  public token: string;
  public user_id: number;

  public trans_id: number;
  public trans_type: string;
  public user_name: string;
  public game: string;
  public coefficient: string;
  public transaction_value: string;
  public admin_username: string;

  public trans_code: string;
  public create_time: string;
  public father_name: string;
  public play: string;
  public order_id: string;
  public available_bal: string;
  public ip: string;

  public update_time: string;
  public is_tester: string;
  public issue: string;
  public bet_code: string;
  public remarks: string;
  public proxy_ip: string;

  constructor(
    public api: ApiService,
    public modalService: NzModalService
  ) { 
    if (localStorage.getItem('token')) {
      this.token = localStorage.getItem('token');
    }

    this.user_id = Number(window.location.pathname.split('/', 4).pop());
  }

  ngOnInit() {
    this.api.get_transaction_detail_info(this.token, this.user_id).subscribe(data => {
      if (data && data['isSuccess']) {
        let info = data['data'];

        this.trans_id = info['id'];
        this.trans_type = info['transaction_type'];
        this.user_name = info['username'];
        this.coefficient = info['coefficient'];
        this.transaction_value = info['transaction_value'];
        this.admin_username = info['admin_username'];
        this.trans_code = info['code'];
        this.create_time = info['create_time'];
        this.father_name = '默认';
        this.game = info['game'];
        this.play = info['play'];
        this.order_id = info['bet_id'];
        this.available_bal = info['balance'];
        this.ip = info['ip'];
        this.update_time = info['update_time'];
        this.is_tester = info['is_tester'] === 0 ? '否' : '是';
        this.issue = info['issue'];
        this.bet_code = info['bet_code'];
        this.remarks = '无';
        this.proxy_ip = info['proxy_ip'];

      } else {
        let error = data['data']['error'];
        this.modalService.error({
          nzTitle: '温馨提示',
          nzContent: error
        });
      };
    })
  }


}
