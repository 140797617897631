import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { ApiService } from 'src/app/providers/api/api.service';

@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.scss']
})
export class MainPageComponent implements OnInit {

  public isCollapsed = false;
  // 当前菜单
  public current_menu: string;
  public menu_list: Array<any>;

  public username: string;
  public token: string;

  constructor(
    private router: Router,
    public api: ApiService,
  ) {
    if (localStorage.getItem('token')) {
      this.token = localStorage.getItem('token');
    }

    this.get_current_menu_by_url(location.pathname);

    this.username = localStorage.getItem('username');

    this.get_main_menu_list_handler();
  }

  ngOnInit() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event) => {
      this.get_current_menu_by_url(event['url']);
    });
  }

  /**
   * 获取当前菜单
   *
   * @param {string} url
   * @memberof MainPageComponent
   */
  public get_current_menu_by_url(url: string) {
    this.current_menu = url.split('/')[2];
  }

  /**
   * 获取主菜单栏
   *
   * @memberof MainPageComponent
   */
  public get_main_menu_list_handler() {
    this.api.get_menu_list_handler(this.token).subscribe(data => {
      if (data) {
        this.menu_list = data;
      } else {
        this.menu_list = [];
      };
    });
  };
}

