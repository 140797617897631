import { Component, OnInit } from '@angular/core';
import { startOfMonth, startOfDay, startOfISOWeek, addYears, addDays } from 'date-fns';
import * as differenceInCalendarDays from 'date-fns/difference_in_calendar_days';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/providers/api/api.service';
import { Utils } from 'src/app/common/util';
import { NzModalService, NzMessageService } from 'ng-zorro-antd';

@Component({
  selector: 'app-record-page-bet',
  templateUrl: './record-page-bet.component.html',
  styleUrls: ['./record-page-bet.component.scss']
})
export class RecordPageBetComponent implements OnInit {

  // token
  public token: string;
  public loading = false;

  public detail_show = false;

  public games_type: any = [];
  public plays_type: any = [];
  // 报表数据
  public bet_data: any = [];

  public bet_code: string;
  public user_name: string;
  public is_tester = 'all';
  public status = 'all';
  public issue: string;
  public game = 'all';
  public play = 'all';
  public coefficient = 'all';
  public bet_source = 'all';

  // 分页数据
  public total_num: number;
  public cur_page = 1;
  public page_size = 100;

  public bet_id: string;
  public trace_id: string;
  public dgame: string;
  public dplay: string;
  public multiple: string;
  public position: string;
  public lottery: string;
  public bet_content: string;
  public bet_time: string;
  public open_prize: string;
  public ip: string;

  public dbet_code: string;
  public username: string;
  public bet_model: string;
  public dbet_source: string;
  public up_line: string;
  public single_amount: string;
  public cancel_time: string;
  public create_time: string;
  public proxy_ip: string;
  public prize_status: string;

  public dis_tester: string;
  public dissue: string;
  public bet_value: string;
  public bet_status: string;
  public open_status: string;
  public cal_time: string;
  public update_time: string;
  public manager: string;

  public domain_name = this.api.domain_name;
  // 时间范围
  public time_range: any = { '今日': [startOfDay(new Date()), new Date()], '本周': [startOfISOWeek(new Date()), new Date()], '本月': [startOfMonth(new Date()), new Date()] };
  public date_range: any = [startOfDay(new Date()), new Date()];
  public start_time: any = Utils.formate_time(this.date_range[0], 'yyyy-MM-dd');
  public end_time: any = Utils.formate_time(this.date_range[1], 'yyyy-MM-dd');
  public down_url: any;
  // public date_range: any = [addYears(new Date(2014, 4, 1), 2), new Date()];
  public disabled_range: any = (current: Date): boolean => {
    return differenceInCalendarDays(current, new Date()) > 0;
  };



  constructor(
    public router: Router,
    public api: ApiService,
    public modalService: NzModalService,
    public message: NzMessageService
  ) {
    if (localStorage.getItem('token')) {
      this.token = localStorage.getItem('token');
    }

    this.api.get_game_type_info(this.token).subscribe(data => {
      let obj = {label: '不限', value: 'all'};

      if (data && data['isSuccess']) {
        let games = data['data'];
        games.map(item => {
          if (item['value'] !== 'NULL') {
            this.games_type.push(item);
          }
        });
        this.games_type.unshift(obj);
      } else {
        // 错误提示
        let error = data['data']['error'];
        this.modalService.error({
          nzTitle: '温馨提示',
          nzContent: error
        });
      }
    })

    this.get_bet_list();
   }

  ngOnInit() {
    this.down_url = `${this.domain_name}/export-csv/bet?start_time=${this.start_time}&end_time=${this.end_time}&username=${this.user_name ? this.user_name : ''}`;
  }

  /**
   * 前往投注详情页面
   *
   * @param {*} bet_id
   * @memberof RecordPageBetComponent
   */
  public goto_detail_bet_page_hanlder(bet_id: any) {
    this.detail_show = true;
    // this.router.navigate([`/main/bet-detail/${bet_id}`]);
    let id = bet_id;
    this.api.get_bet_detail_info(this.token, id).subscribe(data => {
      if (data && data['isSuccess']) {
        let info = data['data'];
        this.bet_id = info['id'];
        this.trace_id = info['trace_id'];
        this.dgame = info['game'];
        this.dplay = info['play'];
        this.multiple = info['multiple'];
        this.position = info['position'];
        this.lottery = info['lottery'].join(',');
        this.bet_content = info['bet_content'];
        this.bet_time = info['bet_time'];
        this.open_prize = info['send_prize_time'];
        this.ip = info['ip'];
        this.dbet_code = info['bet_code'];
        this.username = info['username'];
        this.bet_model = info['coefficient'];
        this.dbet_source = info['bet_source'] === 'app' ? 'App' : '网站';
        this.up_line = info['is_gt_max_prize'] === 0 ? '未超限' : '已超限';
        this.single_amount = info['prize_value'];
        this.cancel_time = info['cancel_time'];
        this.create_time = info['create_time'];
        this.proxy_ip = info['proxy_ip'];
        this.dissue = info['issue'];
        this.bet_value = info['bet_value'];
        this.bet_status = info['bet_status'];
        this.open_status = info['open_status'];
        this.cal_time = info['calculate_time'];
        this.update_time = info['update_time'];
        this.manager = info['manager'];
        this.dis_tester = info['is_tester'] === 0 ? '否' : '是';
        this.prize_status = info['prize_status'] === 0 ? '派奖未完成' : '派奖已完成';

      } else {
        // 错误提示
        let error = data['data']['error'];
        this.modalService.error({
          nzTitle: '温馨提示',
          nzContent: error
        });
      }
    })
  }

  /**
   *  时间范围
   *
   * @param {*} e
   * @memberof RecordPageBetComponent
   */
  public disable_date_dandler(e) {

    let select_time = [];
    let days: number;

    if (e) {
      select_time = e;

      this.start_time = Utils.formate_time(select_time[0], 'yyyy-MM-dd');
      this.end_time = Utils.formate_time(select_time[1], 'yyyy-MM-dd');
      this.down_url = `${this.domain_name}/export-csv/bet?start_time=${this.start_time}&end_time=${this.end_time}&username=${this.user_name ? this.user_name : ''}`;
      let t = Utils.formate_time(select_time[0], 'yyyy, MM, dd');

      days = differenceInCalendarDays(select_time[0], select_time[1]);

      if (days < -40 || days > 40) {

        this.date_range = [new Date(select_time[0]), new Date(addDays(new Date(t), 40)) ];

        this.message.create('warning', `时间间隔不可超过40天！`);
      }
    }

  }

  /**
   * 获取报表数据
   *
   * @memberof RecordPageBetComponent
   */
  public get_bet_list() {
    this.loading = true;
    let start_time = this.start_time = Utils.formate_time(this.date_range[0], 'yyyy-MM-dd hh:mm:ss');
    let end_time = this.end_time = Utils.formate_time(this.date_range[1], 'yyyy-MM-dd hh:mm:ss');

    this.down_url = `${this.domain_name}/export-csv/bet?start_time=${this.start_time}&end_time=${this.end_time}&username=${this.user_name ? this.user_name : ''}`;
    this.api.get_bet_list_info(this.token, this.cur_page, this.page_size, start_time, end_time,
       this.bet_code, this.user_name, this.is_tester, this.status, this.issue,
       this.game, this.play, this.coefficient, this.bet_source).subscribe(data => {
      if (data && data['isSuccess']) {
        this.loading = false;

        let info = data['data'];
        this.cur_page = info['current_page'];
        this.page_size = Number(info['per_page']);
        this.total_num = info['total'];

        this.bet_data = [];
        info['data'].forEach(e => {
            let bet_record = new BetRecord();
            bet_record.id = e['id'];
            bet_record.code = e['bet_code'];
            bet_record.trace_id = e['trace_id'];
            bet_record.user_name = e['username'];
            bet_record.user_type = e['is_tester'] === 0 ? '否' : '是';
            bet_record.game_name = e['game_name'];
            bet_record.issue = e['issue'];
            bet_record.play_name = e['play_name'];
            bet_record.multiple = e['multiple'];
            bet_record.bet_mode = e['coefficient'];
            bet_record.bet_value = e['bet_value'];
            bet_record.prize_value = e['prize_value'];
            bet_record.bet_time = e['bet_time'];
            bet_record.bet_ip = e['ip'];
            bet_record.bet_status = e['bet_status'];
            bet_record.bet_content = e['bet_content'];
            this.bet_data.push(bet_record);
        });
      } else {
          // 错误提示
        let error = data['data']['error'];
        this.modalService.error({
          nzTitle: '温馨提示',
          nzContent: error
        });
      }
    })
  }

  /**
   * 切换游戏查找玩法
   *
   * @param {*} e
   * @memberof RecordPageBetComponent
   */
  public change_game_name_handler(e) {
    let id = Number(e);

    this.update_play_name_handler(id);
  }

  /**
   * 玩法数据
   *
   * @memberof RecordPageBetComponent
   */
  public update_play_name_handler(id: number) {
    this.plays_type = [];

    this.games_type.map((item, i) => {
      if (item['value'] === id) {
        item['children'].forEach(e => {
          let obj = {};
          obj['value'] = e['value'];
          obj['label'] = e['label'];
          this.plays_type.push(obj);
        });
      };
    })
  }


  /**
   * 切换分页
   *
   * @param {*} e
   * @memberof RecordPageBetComponent
   */
  public change_current_pagination_handler(e: any) {
    this.cur_page = Number(e);
    this.get_bet_list();
  }

  /**
   * 当前页至1
   *
   * @memberof RecordPageBetComponent
   */
  public set_current_page_handler() {
    this.cur_page = 1;
  }

  public close() {
    this.detail_show = false;
  }
}

class BetRecord {
  // id
  public id: string;
  // 编号
  public code: string;
  // 追号ID
  public trace_id: string;
  // 用户名称
  public user_name: string;
  // 测试用户
  public user_type: string;
  // 游戏名称
  public game_name: string;
  // 奖期期号
  public issue: string;
  // 玩法名称
  public play_name: string;
  // 投注倍数
  public multiple: number;
  // 投注模式
  public bet_mode: string;
  // 投注金额
  public bet_value: number;
  // 奖金金额
  public prize_value: number;
  // 投注时间
  public bet_time: string;
  // 投注IP
  public bet_ip: string;
  // 投注状态
  public bet_status: string;
  // 投注内容
  public bet_content: string;

  constructor() {

  }
}
