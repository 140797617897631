import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-detail-profit',
  templateUrl: './detail-profit.component.html',
  styleUrls: ['./detail-profit.component.scss']
})
export class DetailProfitComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
