import { Component, OnInit } from '@angular/core';
import { startOfMonth, startOfDay, startOfISOWeek, addYears, addDays } from 'date-fns';
import * as differenceInCalendarDays from 'date-fns/difference_in_calendar_days';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/providers/api/api.service';
import { Utils } from 'src/app/common/util';
import { NzModalService, NzMessageService } from 'ng-zorro-antd';

@Component({
  selector: 'app-record-page-trace',
  templateUrl: './record-page-trace.component.html',
  styleUrls: ['./record-page-trace.component.scss']
})
export class RecordPageTraceComponent implements OnInit {

  // token
  public token: string;
  public loading = false;
  public detail_show = false;

  public trace_data: any = [];
  public games_type: any = [];
  public plays_type: any = [];

  public trace_code: string;
  public user_name: string;
  public is_tester = 'all';
  public trace_status = 'all';
  public issue: string;
  public game = 'all';
  public play = 'all';
  public bet_model = 'all';
  public bet_source = 'all';

  // 分页数据
  public total_num: number;
  public cur_page = 1;
  public page_size = 100;

  // detail数据

  public trace_id: string;
  public d_user_name: string;
  public proxy_ip: string;
  public d_game: string;
  public trace_issue: number;
  public d_bet_model: string;
  public finish_val: number;
  public bet_position: string;
  public bet_content: string;
  public bet_time: string;
  public d_trace_code: string;
  public create_time: string;
  public d_play: string;
  public finish_issue: string;
  public sigle_issue_val: string;
  public cancel_val: string;
  public d_bet_source: string;
  public cancel_time: string;
  public ip: string;
  public update_time: string;
  public start_issue: string;
  public cancel_issue: string;
  public trace_val: string;
  public d_trace_status: string;
  public win_stop: string;
  public end_time: string;

  public domain_name = this.api.domain_name;

  // 时间范围
  public time_range: any = { '今日': [startOfDay(new Date()), new Date()], '本周': [startOfISOWeek(new Date()), new Date()], '本月': [startOfMonth(new Date()), new Date()] };
  public date_range: any = [startOfDay(new Date()), new Date()];
  public start_time: any = Utils.formate_time(this.date_range[0], 'yyyy-MM-dd');
  public d_end_time: any = Utils.formate_time(this.date_range[1], 'yyyy-MM-dd');
  public username: any;
  public down_url: any;

  // public date_range: any = [addYears(new Date(2014, 4, 1), 2), new Date()];
  public disabled_range: any = (current: Date): boolean => {
    return differenceInCalendarDays(current, new Date()) > 0;
  };

  constructor(
    public router: Router,
    public api: ApiService,
    public modalService: NzModalService,
    public message: NzMessageService
  ) {
    console.log(this.d_end_time);
    if (localStorage.getItem('token')) {
      this.token = localStorage.getItem('token');
    };

    this.api.get_game_type_info(this.token).subscribe(data => {
      let obj = {label: '不限', value: 'all'};

      if (data && data['isSuccess']) {
        let games = data['data'];
        games.map(item => {
          if (item['value'] !== 'NULL') {
            this.games_type.push(item);
          }
        })
        this.games_type.unshift(obj);
      } else {
        // 错误提示
        let error = data['data']['error'];
        this.modalService.error({
          nzTitle: '温馨提示',
          nzContent: error
        });
      }
    })

    this.get_trace_list_info();
  }

  ngOnInit() {
    this.down_url = `${this.domain_name}/export-csv/trace?start_time=${this.start_time}&end_time=${this.d_end_time}&username=${this.user_name ? this.user_name : ''}`;
  }

  /**
   * 前往追号详情页面
   *
   * @param {*} trace_id
   * @memberof RecordPageTraceComponent
   */
  public goto_detail_trace_page_hanlder(trace_id: any) {
    this.detail_show = true;
    // this.router.navigate([`/main/trace-detail/${trace_id}`]);
    let id = trace_id;
    this.api.get_trace_detail_info(this.token, id).subscribe(data => {
      if (data && data['isSuccess']) {
        let info = data['data'];

        this.trace_id = info['id'];
        this.d_user_name = info['username'];
        this.proxy_ip = info['proxy_ip'];
        this.d_game = info['game'];
        this.trace_issue = info['total_issues'];
        this.d_bet_model = info['game_mode'];
        this.finish_val = info['complete_value'];
        this.bet_position = info['position'];
        this.bet_content = info['bet_number'];
        this.bet_time = info['trace_time'];
        this.d_trace_code = info['trace_code'];
        this.create_time = info['create_time'];
        this.d_play = info['play'];
        this.finish_issue = info['complete_issue'];
        this.sigle_issue_val = info['single_amount'];
        this.cancel_val = info['cancel_value'];
        this.cancel_time = info['cancel_time'];
        this.ip = info['ip'];
        this.update_time = info['update_time'];
        this.start_issue = info['start_issue'];
        this.cancel_issue = info['cancel_issue'];
        this.trace_val = info['trace_value'];
        this.d_trace_status = info['trace_status'];
        this.end_time = info['stop_time'];
        this.win_stop = info['is_stop'] === 1 ? '是' : '否';
        this.d_bet_source = info['bet_source'];

      } else {
        let error = data['data']['error'];
        this.modalService.error({
          nzTitle: '温馨提示',
          nzContent: error
        });
      };
    })
  }

  /**
   *  时间范围
   *
   * @param {*} e
   * @memberof RecordPageTraceComponent
   */
  public disable_date_dandler(e) {
    let select_time = [];
    let days: number;

    if (e) {
      select_time = e;
      this.start_time = Utils.formate_time(select_time[0], 'yyyy-MM-dd');
      this.d_end_time = Utils.formate_time(select_time[1], 'yyyy-MM-dd');
      this.down_url = `${this.domain_name}/export-csv/trace?start_time=${this.start_time}&end_time=${this.d_end_time}&username=${this.user_name ? this.user_name : ''}`;

      let t = Utils.formate_time(select_time[0], 'yyyy, MM, dd');

      days = differenceInCalendarDays(select_time[0], select_time[1]);

      if (days < -40 || days > 40) {

        this.date_range = [new Date(select_time[0]), new Date(addDays(new Date(t), 40)) ];

        this.message.create('warning', `时间间隔不可超过40天！`);
      }
    }

  }

  /**
   *  追号报表
   *
   * @memberof RecordPageTraceComponent
   */
  public get_trace_list_info() {
    this.loading = true;
    let start_time = this.start_time = Utils.formate_time(this.date_range[0], 'yyyy-MM-dd hh:mm:ss');
    let end_time = this.d_end_time = Utils.formate_time(this.date_range[1], 'yyyy-MM-dd hh:mm:ss');

    this.down_url = `${this.domain_name}/export-csv/trace?start_time=${this.start_time}&end_time=${this.d_end_time}&username=${this.user_name ? this.user_name : ''}`;
    this.api.get_trace_list_info(
      this.token,
      this.cur_page,
      this.page_size,
      start_time,
      end_time,
      this.trace_code,
      this.user_name,
      this.is_tester,
      this.trace_status,
      this.issue,
      this.game,
      this.play,
      this.bet_model,
      this.bet_source
      ).subscribe(data => {
      if (data && data['isSuccess']) {
        this.loading = false;

        let info = data['data'];
        this.cur_page = info['current_page'];
        this.page_size = Number(info['per_page']);
        this.total_num = info['total'];

        this.trace_data = [];

        info['data'].forEach(e => {
          let trace_record = new TraceRecord();
          trace_record.id = e['id'];
          trace_record.code = e['trace_code'];
          trace_record.user_name = e['username'];
          trace_record.user_type = e['is_tester'];
          trace_record.game_name = e['game_name'];
          trace_record.play_name = e['play_name'];
          trace_record.bet_content = e['bet_number'];
          trace_record.trace_issue_num = e['total_issues'];
          trace_record.bet_mode = e['coefficient'];
          trace_record.bet_value = e['trace_value'];
          trace_record.prize_value = e['prize_value'];
          trace_record.trace_status = e['trace_status'];
          trace_record.bet_ip = e['ip'];
          trace_record.bet_time = e['create_time'];
          this.trace_data.push(trace_record);
        });
      } else {

      };
    })
  }

  /**
   * 切换游戏
   *
   * @param {*} e
   * @memberof RecordPageTraceComponent
   */
  public change_game_name_handler(e) {
    let id = Number(e);

    this.update_play_name_handler(id);
  }

  /**
   * 玩法数据
   *
   * @param {number} id
   * @memberof RecordPageTraceComponent
   */
  public update_play_name_handler(id: number) {
    this.plays_type = [];

    this.games_type.map((item, i) => {
      if (item['value'] === id) {
        item['children'].forEach(e => {
          let obj = {};
          obj['value'] = e['value'];
          obj['label'] = e['label'];
          this.plays_type.push(obj);
        });
      };
    })
  }

  /**
   * 切换分页
   *
   * @param {*} e
   * @memberof RecordPageTraceComponent
   */
  public change_current_pagination_handler(e: any) {
    this.cur_page = Number(e);
    this.get_trace_list_info();
  }

  /**
   * 当前页至1
   *
   * @memberof RecordPageTraceComponent
   */
  public set_current_page_handler() {
    this.cur_page = 1;
  }

  public close() {
    this.detail_show = false;
  }
}

class TraceRecord {
  // id
  public id: string;
  // 编号
  public code: string;
  // 用户名称
  public user_name: string;
  // 测试用户
  public user_type: string;
  // 游戏名称
  public game_name: string;
  // 玩法名称
  public play_name: string;
  // 投注内容
  public bet_content: string;
  // 追号期数
  public trace_issue_num: number;
  // 投注模式
  public bet_mode: string;
  // 投注金额
  public bet_value: number;
  // 奖金金额
  public prize_value: number;
  // 追号状态
  public trace_status: string;
  // 投注IP
  public bet_ip: string;
  // 投注时间
  public bet_time: string;

  constructor() {

  }
}
