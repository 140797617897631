import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RecordPageProfitComponent } from './page/record-page-profit/record-page-profit.component';
import { RecordPageBetComponent } from './page/record-page-bet/record-page-bet.component';
import { RecordPageTraceComponent } from './page/record-page-trace/record-page-trace.component';
import { RecordPageTransactionComponent } from './page/record-page-transaction/record-page-transaction.component';
import { RecordPageTransferComponent } from './page/record-page-transfer/record-page-transfer.component';
import { NonexistentPageComponent } from './page/nonexistent-page/nonexistent-page.component';
import { LoginPageComponent } from './page/login-page/login-page.component';
import { MainPageComponent } from './page/main-page/main-page.component';
import { DetailPageTransactionComponent } from './page/detail-page-transaction/detail-page-transaction.component';
import { DetailPageBetComponent } from './page/detail-page-bet/detail-page-bet.component';
import { DetailPageTraceComponent } from './page/detail-page-trace/detail-page-trace.component';
import { JackpotRecordComponent } from './page/jackpot-record/jackpot-record.component';
import { GameConfigPageComponent } from './page/game-config-page/game-config-page.component';

const routes: Routes = [
  // 登录页面
  { path: '', redirectTo: 'login-page', pathMatch: 'full' },
  { path: 'login-page', component: LoginPageComponent },
  {
    path: 'main', component: MainPageComponent, children: [
      // 盈亏报表
      { path: 'profit-record', component: RecordPageProfitComponent },
      // 账变报表
      { path: 'transaction-record', component: RecordPageTransactionComponent },
      { path: 'transaction-detail/:id', component: DetailPageTransactionComponent },
      // 投注报表
      { path: 'bet-record', component: RecordPageBetComponent },
      { path: 'bet-detail/:id', component: DetailPageBetComponent },
      // 追号报表
      { path: 'trace-record', component: RecordPageTraceComponent },
      { path: 'trace-detail/:id', component: DetailPageTraceComponent },
      // 奖池配置
      { path: 'jackpot-record', component: JackpotRecordComponent },
      // 转账报表
      { path: 'transfer-record', component: RecordPageTransferComponent },
      // 游戏配置
      { path: 'game-record', component: GameConfigPageComponent }
    ]
  },
  // 404页面
  { path: '**', component: NonexistentPageComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
