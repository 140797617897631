import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/providers/api/api.service';
import { NzModalService } from 'ng-zorro-antd';

@Component({
  selector: 'app-detail-page-trace',
  templateUrl: './detail-page-trace.component.html',
  styleUrls: ['./detail-page-trace.component.scss']
})
export class DetailPageTraceComponent implements OnInit {

  public token: string;
  public user_id: number;

  public trace_id: string;
  public user_name: string;
  public proxy_ip: string;
  public game: string;
  public trace_issue: number;
  public bet_model: string;
  public finish_val: number;
  public bet_position: string;
  public bet_content: string;
  public bet_time: string;
  public trace_code: string;
  public create_time: string;
  public play: string;
  public finish_issue: string;
  public sigle_issue_val: string;
  public cancel_val: string;
  public bet_source: string;
  public cancel_time: string;
  public ip: string;
  public update_time: string;
  public start_issue: string;
  public cancel_issue: string;
  public trace_val: string;
  public trace_status: string;
  public win_stop: string;
  public end_time: string;

  constructor(
    public api: ApiService,
    public modalService: NzModalService
  ) { 
    if (localStorage.getItem('token')) {
      this.token = localStorage.getItem('token');
    }
    this.user_id = Number(window.location.pathname.split('/', 4).pop());
  }

  ngOnInit() {
    this.api.get_trace_detail_info(this.token, this.user_id).subscribe(data => {
      if (data && data['isSuccess']) {
        let info = data['data'];

        this.trace_id = info['id'];
        this.user_name = info['username'];
        this.proxy_ip = info['proxy_ip'];
        this.game = info['game'];
        this.trace_issue = info['total_issues'];
        this.bet_model = info['game_mode'];
        this.finish_val = info['complete_value'];
        this.bet_position = info['position'];
        this.bet_content = info['bet_number'];
        this.bet_time = info['trace_time'];
        this.trace_code = info['trace_code'];
        this.create_time = info['create_time'];
        this.play = info['play'];
        this.finish_issue = info['complete_issue'];
        this.sigle_issue_val = info['single_amount'];
        this.cancel_val = info['cancel_value'];
        this.cancel_time = info['cancel_time'];
        this.ip = info['ip'];
        this.update_time = info['update_time'];
        this.start_issue = info['start_issue'];
        this.cancel_issue = info['cancel_issue'];
        this.trace_val = info['trace_value'];
        this.trace_status = info['trace_status'];
        this.end_time = info['stop_time'];
        this.win_stop = info['is_stop'] === 1 ? '是' : '否';
        this.bet_source = info['bet_source'];
      
      } else {
        let error = data['data']['error'];
        this.modalService.error({
          nzTitle: '温馨提示',
          nzContent: error
        });
      };
    })
  }

}
