import * as CryptoJS from 'crypto-js';

export class Utils {

    
   /**
    * 游戏类型
    *
    * @static
    * @type {Array<any>}
    * @memberof Utils
    */
   static game_type_array: Array<any>;

   /**
    * 游戏列表
    *
    * @static
    * @type {Array<any>}
    * @memberof Utils
    */
   static game_list: Array<any>;

    // 密钥
    // bet
    static key_bet_str = 'C194V1RBJG8MJPEL';
    static iv_bet_str = 'ARC49SBQE76B8QZT';
    // info
    static key_info_str = '90R1XU#DEDghEObz';
    static iv_info_str = '&$n$^VGfTT%VDdeX';

    /**
     * 加密操作
     *
     * @static
     * @param {*} info
     * @param {Number} type 1投注加密 0信息加密
     * @returns {string}
     * @memberof Utils
     */
    static encode_info_handler(info: any, type: number): string {
        let key;
        let iv;

        if (type) {
            key = CryptoJS.enc.Utf8.parse(Utils.key_bet_str);
            iv = CryptoJS.enc.Utf8.parse(Utils.iv_bet_str);
        } else {
            key = CryptoJS.enc.Utf8.parse(Utils.key_info_str);
            iv = CryptoJS.enc.Utf8.parse(Utils.iv_info_str);
        }

        let encrypt = CryptoJS.AES.encrypt(info, key, {
            iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });

        let str = encrypt.toString();

        return str;
    }


    /**
     *  格式化时间
     * 
     * @static
     * @param {Date} data
     * @param {string} fmt
     * @returns {string}
     * @memberof Utils
     */
    static formate_time(data: Date, fmt: string): string {

        let o;
        let k;
        o = {
            'M+': data.getMonth() + 1,                  /** 月份 */
            'd+': data.getDate(),                       /** 日 */
            'h+': data.getHours(),                      /** 小时 */
            'm+': data.getMinutes(),                    /** 分 */
            's+': data.getSeconds(),                    /** 秒 */
        };

        if (/(y+)/.test(fmt)) {
            fmt = fmt.replace(RegExp.$1, (data.getFullYear() + '').substr(4 - RegExp.$1.length));
        }

        for (k in o) {
            if (new RegExp('(' + k + ')').test(fmt)) {
                fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)));
            }
        }

        return fmt;
    }
}
