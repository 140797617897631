import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/providers/api/api.service';
import { NzModalService, NzMessageService } from 'ng-zorro-antd';

@Component({
  selector: 'app-game-config-page',
  templateUrl: './game-config-page.component.html',
  styleUrls: ['./game-config-page.component.scss']
})
export class GameConfigPageComponent implements OnInit {

  public token: string;
  public loading = false;
  public tableData: any = [];

  constructor(
    public api: ApiService,
    public modalService: NzModalService,
    private message: NzMessageService
  ) { }

  ngOnInit() {
    if (localStorage.getItem('token')) {
      this.token = localStorage.getItem('token');
    }

    this.get_game_list_handler();
  }

  public get_game_list_handler() {
    this.loading = true;

    this.api.get_game_list_handler(this.token).subscribe(data => {
      if (data && data['isSuccess']) {
        this.loading = false;

        let info = data['data'];
        this.tableData = [];

        info.forEach(e => {
          let game_info = new GameInfo();
          game_info.game_id = e.game_id;
          game_info.code = e.code;
          game_info.name = e.name;
          game_info.open = e.open;
          game_info.updated_at = e.updated_at;
          this.tableData.push(game_info);
        });
      } else {
        // 错误提示
        let error = data['data']['error'];
        this.modalService.error({
          nzTitle: '温馨提示',
          nzContent: error
        });
      };
    });
  }

  /**
   * 开启关闭
   *
   * @param {*} data
   * @memberof GameConfigPageComponent
   */
  public changeStatus(data) {
    this.loading = true;
    let id = data['game_id'];
    let open = data['open'] ? 0 : 1;

    this.api.get_game_config_handler(this.token, id, open).subscribe( res => {
      if (res && res['isSuccess']) {
        this.loading = false;
        this.get_game_list_handler();
        this.message.create('success', '修改状态成功！');
      } else {
        this.loading = false;
         // 错误提示
        let error = res['data']['error'];
        this.modalService.error({
           nzTitle: '温馨提示',
           nzContent: error
         });
      };
    })
  }
}

class GameInfo {
  game_id: string;
  code: string;
  name: string;
  open: string;
  updated_at: string;
}
