import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Utils } from 'src/app/common/util';
import { ApiService } from 'src/app/providers/api/api.service';
import { NzMessageService } from 'ng-zorro-antd';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {

  public validateForm: FormGroup;
  public is_loading = false;

  constructor(
    public fb: FormBuilder,
    public router: Router,
    private api: ApiService,
    private message: NzMessageService,
  ) { }

  ngOnInit() {
    this.validateForm = this.fb.group({
      userName: ['', [Validators.required, Validators.maxLength(16), Validators.pattern('[0-9A-Za-z]{4,16}$')]],
      password: ['', [Validators.required, Validators.maxLength(16), Validators.pattern('[0-9A-Za-z]{6,16}$')]]
    });
  }

  public submitForm(): void {
    if (this.validateForm.valid) {
      this.is_loading = true;

      let username_val = this.validateForm.get('userName').value;
      let password_val = Utils.encode_info_handler(this.validateForm.get('password').value, 0);

      localStorage.setItem('username', username_val);
      localStorage.setItem('password', password_val);

      this.get_user_login_infor_handler(username_val, password_val);

    } else {
      this.message.create('error', '您输入的格式有误哦！');
    }
  }

  public get_user_login_infor_handler(username, password) {
      const id = this.message.loading('登录中...', { nzDuration: 3000 }).messageId;

      this.api.get_login_info(username, password).subscribe(data => {
        this.is_loading = false;


        if (data && data['isSuccess']) {
          this.message.remove(id);
          let info = data['data'];

          localStorage.setItem('token', info['token']);
          localStorage.setItem('username', info['username']);

          this.router.navigate(['/main/profit-record']);
        } else {
          this.message.remove(id);
          this.message.create('error', data['data']['error']);
        }
      })
  }
}
