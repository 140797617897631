import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgZorroAntdModule, NZ_I18N, zh_CN } from 'ng-zorro-antd';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';
import zh from '@angular/common/locales/zh';
import { RecordPageBetComponent } from './page/record-page-bet/record-page-bet.component';
import { RecordPageTraceComponent } from './page/record-page-trace/record-page-trace.component';
import { RecordPageTransactionComponent } from './page/record-page-transaction/record-page-transaction.component';
import { RecordPageTransferComponent } from './page/record-page-transfer/record-page-transfer.component';
import { LoginPageComponent } from './page/login-page/login-page.component';
import { DetailPageBetComponent } from './page/detail-page-bet/detail-page-bet.component';
import { DetailPageTraceComponent } from './page/detail-page-trace/detail-page-trace.component';
import { RecordPageProfitComponent } from './page/record-page-profit/record-page-profit.component';
import { NonexistentPageComponent } from './page/nonexistent-page/nonexistent-page.component';
import { MainPageComponent } from './page/main-page/main-page.component';
import { DetailPageTransactionComponent } from './page/detail-page-transaction/detail-page-transaction.component';
import { GameNamePipe } from './pipe/game/game-name.pipe';
import { PlayNamePipe } from './pipe/play/play-name.pipe';
import { TransferTypePipe } from './pipe/transfer/transfer-type.pipe';
import { BetModelPipe } from './pipe/bet/bet-model.pipe';
import { BetStatusPipe } from './pipe/betStatus/bet-status.pipe';
import { TraceStatusPipe } from './pipe/traceStatus/trace-status.pipe';
import { DetailProfitComponent } from './page/detail-profit/detail-profit.component';
import { JackpotRecordComponent } from './page/jackpot-record/jackpot-record.component';
import { GameConfigPageComponent } from './page/game-config-page/game-config-page.component';

registerLocaleData(zh);

@NgModule({
  declarations: [
    AppComponent,
    RecordPageBetComponent,
    RecordPageTraceComponent,
    RecordPageTransactionComponent,
    RecordPageTransferComponent,
    LoginPageComponent,
    DetailPageBetComponent,
    DetailPageTraceComponent,
    RecordPageProfitComponent,
    NonexistentPageComponent,
    MainPageComponent,
    DetailPageTransactionComponent,
    GameNamePipe,
    PlayNamePipe,
    TransferTypePipe,
    BetModelPipe,
    BetStatusPipe,
    TraceStatusPipe,
    DetailProfitComponent,
    JackpotRecordComponent,
    GameConfigPageComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgZorroAntdModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule
  ],
  providers: [{ provide: NZ_I18N, useValue: zh_CN }],
  bootstrap: [AppComponent]
})
export class AppModule { }
