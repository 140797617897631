import { Component, OnInit } from '@angular/core';
import { startOfMonth, startOfDay, startOfISOWeek, addYears, addDays } from 'date-fns';
import * as differenceInCalendarDays from 'date-fns/difference_in_calendar_days';
import { Router, ActivatedRoute } from '@angular/router';
import { Utils } from 'src/app/common/util';
import { ApiService } from 'src/app/providers/api/api.service';
import { NzModalService, NzMessageService } from 'ng-zorro-antd';

@Component({
  selector: 'app-record-page-transaction',
  templateUrl: './record-page-transaction.component.html',
  styleUrls: ['./record-page-transaction.component.scss']
})
export class RecordPageTransactionComponent implements OnInit {

  // token
  public token: string;
  public loading = false;

  public detail_show = false;

  // 报表数据
  public trans_data: any = [];
  // 帐变类型
  public trans_type: any = [];
  // 游戏数据
  public games_type: any = [];

  // 分页数据
  public total_num: number;
  public cur_page = 1;
  public page_size = 100;

  // 检索条件
  public transaction_type: string; // 帐变类型
  public transaction_code: string; // 帐变编号
  public game = 'all'; // 游戏帐变
  public min_amount: number; // 帐变金额
  public max_amount: number;
  public username: string;
  public ip: string;
  public coefficient = 'all'; // 投注模式
  public is_tester = 'all';

  // 详情数据
  public trans_id: number;
  public dtrans_type: string;
  public user_name: string;
  public dgame: string;
  public dcoefficient: string;
  public transaction_value: string;
  public admin_username: string;

  public trans_code: string;
  public create_time: string;
  public father_name: string;
  public play: string;
  public order_id: string;
  public available_bal: string;
  public dip: string;

  public update_time: string;
  public dis_tester: string;
  public issue: string;
  public bet_code: string;
  public remarks: string;
  public proxy_ip: string;

  // 时间范围
  public time_range: any = { '今日': [startOfDay(new Date()), new Date()], '本周': [startOfISOWeek(new Date()), new Date()], '本月': [startOfMonth(new Date()), new Date()] };
  public date_range: any = [startOfDay(new Date()), new Date()];
  // public date_range: any = [addYears(new Date(2014, 4, 1), 2), new Date()];
  public disabled_range: any = (current: Date): boolean => {
    return differenceInCalendarDays(current, new Date()) > 0;
  };

  constructor(
    public router: Router,
    public api: ApiService,
    public modalService: NzModalService,
    public message: NzMessageService
  ) {
    if (localStorage.getItem('token')) {
      this.token = localStorage.getItem('token');
    };

    this.get_transaction_record_info();

    this.api.get_transaction_type_info(this.token).subscribe(data => {

      if (data && data['isSuccess']) {
        this.trans_type = data['data'];

      } else {
        // 错误提示
        let error = data['data']['error'];
        this.modalService.error({
          nzTitle: '温馨提示',
          nzContent: error
        });
      }
    });

    this.api.get_game_type_info(this.token).subscribe(data => {
      // let obj = {label: '全部', value: 100}

      if (data && data['isSuccess']) {
        this.games_type = data['data'];
        // this.games_type.unshift(obj);
      } else {
        // 错误提示
        let error = data['data']['error'];
        this.modalService.error({
          nzTitle: '温馨提示',
          nzContent: error
        });
      }
    })
  }

  ngOnInit() {
  }

  /**
   * 时间范围
   *
   * @param {*} e
   * @memberof RecordPageTransactionComponent
   */
  public disable_date_dandler(e) {
    let select_time = [];
    let days: number;
    
    if (e) {
      select_time = e;
      let t = Utils.formate_time(select_time[0], 'yyyy, MM, dd');

      days = differenceInCalendarDays(select_time[0], select_time[1]);

      if (days < -40 || days > 40) {
        
        this.date_range = [new Date(select_time[0]), new Date(addDays(new Date(t), 40)) ];

        this.message.create('warning', `时间间隔不可超过40天！`);
      }
    }
    
  }

  /**
   * 前往账变详情页面
   *
   * @param {any} transaction_id
   * @memberof RecordPageTransactionComponent
   */
  public goto_detail_transaction_page_hanlder(transaction_id: any) {
    this.detail_show = true;
    let id = transaction_id
    // this.router.navigate([`/main/transaction-detail/${transaction_id}`]);
    this.api.get_transaction_detail_info(this.token, id).subscribe(data => {
      if (data && data['isSuccess']) {
        let info = data['data'];

        this.trans_id = info['id'];
        this.dtrans_type = info['transaction_type'];
        this.user_name = info['username'];
        this.dcoefficient = info['coefficient'];
        this.transaction_value = info['transaction_value'];
        this.admin_username = info['admin_username'];
        this.trans_code = info['code'];
        this.create_time = info['create_time'];
        this.father_name = '默认';
        this.dgame = info['game'];
        this.play = info['play'];
        this.order_id = info['bet_id'];
        this.available_bal = info['balance'];
        this.dip = info['ip'];
        this.update_time = info['update_time'];
        this.is_tester = info['is_tester'] === 0 ? '否' : '是';
        this.issue = info['issue'];
        this.bet_code = info['bet_code'];
        this.remarks = '无';
        this.proxy_ip = info['proxy_ip'];

      } else {
        let error = data['data']['error'];
        this.modalService.error({
          nzTitle: '温馨提示',
          nzContent: error
        });
      };
    })
  }

  /**
   *
   *
   * @memberof RecordPageTransactionComponent
   */
  public get_transaction_record_info() {
    this.loading = true;
    let start_time = Utils.formate_time(this.date_range[0], 'yyyy-MM-dd hh:mm:ss');
    let end_time = Utils.formate_time(this.date_range[1], 'yyyy-MM-dd hh:mm:ss');

    this.api.get_transaction_record(this.token, this.cur_page, this.page_size, start_time, end_time,
      this.transaction_code, this.transaction_type, this.game, this.min_amount, this.max_amount,
      this.username, this.ip, this.coefficient, this.is_tester).subscribe(data => {
        if (data && data['isSuccess']) {
          this.loading = false;

          let info = data['data'];
          this.cur_page = info['current_page'];
          this.page_size = Number(info['per_page']);
          this.total_num = info['total'];

          // this.trans_data = info['data'];
          this.trans_data = [];
          info['data'].forEach(e => {
            let transaction_record = new TransactionRecord();
            transaction_record.id = e['id'];
            transaction_record.code = e['code'];
            transaction_record.create_time = e['create_time'];
            transaction_record.username = e['username'];
            transaction_record.father_name = '默认';
            transaction_record.is_tester = e['is_tester'] === 0 ? '否' : '是';
            transaction_record.transaction_type = e['transaction_type'];
            transaction_record.game = e['game_name'];
            transaction_record.play = e['play_name'];
            transaction_record.coefficient = e['coefficient'];
            transaction_record.transaction_value = e['transaction_value'];
            transaction_record.available_balance = e['available_balance'];
            transaction_record.ip = e['ip'];
            this.trans_data.push(transaction_record);
          });
        } else {
          // 错误提示
          let error = data['data']['error'];
          this.modalService.error({
            nzTitle: '温馨提示',
            nzContent: error
          });
        }
      })

  }


  /**
   * 切换页面
   *
   * @param {*} e
   * @memberof RecordPageTransactionComponent
   */
  public change_current_pagination_handler(e: any) {
    this.cur_page = Number(e);
    this.get_transaction_record_info();
  }

  /**
   * 当前页至1
   *
   * @memberof RecordPageTransactionComponent
   */
  public set_current_page_handler() {
    this.cur_page = 1;
  }

  public close() {
    this.detail_show = false;
  }
}

class TransactionRecord {
  public id: any;
  public code: string;
  public create_time: string;
  public username: string;
  public father_name: string;
  public is_tester: string;
  public transaction_type: string;
  public game: string;
  public play: string;
  public coefficient: string;
  public transaction_value: number;
  public available_balance: number;
  public remarks: string;
  public proxy_ip: string;
  public ip: string;

  constructor() {

  }
}
