import { Component, OnInit } from '@angular/core';
import { startOfDay, startOfISOWeek, startOfMonth, addYears, differenceInCalendarDays, addDays, setDate, isThisISOWeek } from 'date-fns';
import { Utils } from 'src/app/common/util';
import { ApiService } from 'src/app/providers/api/api.service';
import { NzModalService, NzMessageService } from 'ng-zorro-antd';

@Component({
  selector: 'app-record-page-profit',
  templateUrl: './record-page-profit.component.html',
  styleUrls: ['./record-page-profit.component.scss']
})
export class RecordPageProfitComponent implements OnInit {

  public token: string;
  public loading = false;
  public loadingLine = false;

  // 盈亏报表
  public profit_data: any = [];
  public sum_data: any = [];

  public total_bet: number; // 投注总额
  public total_bet_num: number; // 投注总量
  public total_deposit: number; // 投注总额
  public total_prize: number; // 派奖总额
  public total_profit: number; // 总利润
  public total_withdrawal: number; // 投注总额

  public selectIndex = 0;
  public date: any = startOfMonth(new Date());
  // 线路报表
  public line_data: any = [];
  public line_name: string;
  public line_deposit: number;
  public line_withdraw: number;
  public line_bet: number;
  public line_prize: number;
  public line_profit: number;

  // 分页数据
  public total_num: number;
  public cur_page = 1;
  public page_size = 100;

  // 时间范围
  public time_range: any = { '今日': [startOfDay(new Date()), new Date()], '本周': [startOfISOWeek(new Date()), new Date()], '本月': [startOfMonth(new Date()), new Date()] };
  // public date_range: any = [addYears(new Date(2014, 4, 1), 2), new Date()];
  public date_range = [ addDays(new Date(), -30), new Date() ];
  public disabled_range: any = (current: Date): boolean => {
    return differenceInCalendarDays(current, new Date()) > 0;
  };

  constructor(
    public api: ApiService,
    public modalService: NzModalService,
    public message: NzMessageService
  ) {
    if (localStorage.getItem('token')) {
      this.token = localStorage.getItem('token');
    }

    this.get_profit_list_handler();
    this.get_line_list_handler();
  }

  ngOnInit() { }

  /**
   *  时间间隔
   *
   * @param {*} e
   * @memberof RecordPageProfitComponent
   */
  public disable_date_dandler(e) {
    let select_time = [];
    let days: number;
    
    if (e) {
      select_time = e;
      let t = Utils.formate_time(select_time[0], 'yyyy, MM, dd');

      days = differenceInCalendarDays(select_time[0], select_time[1]);

      if (days < -40 || days > 40) {
        
        this.date_range = [new Date(select_time[0]), new Date(addDays(new Date(t), 40)) ];

        this.message.create('warning', `时间间隔不可超过40天！`);
      }
    }
    
  }

  /**
   *  盈亏报表
   *
   * @memberof RecordPageProfitComponent
   */
  public get_profit_list_handler() {
    this.loading = true;
    let start_time = Utils.formate_time(this.date_range[0], 'yyyy-MM-dd');
    let end_time = Utils.formate_time(this.date_range[1], 'yyyy-MM-dd');

    this.api.get_daily_profit_list(
      this.token,
      this.cur_page,
      this.page_size,
      start_time,
      end_time,
    ).subscribe(data => {
      if (data && data['isSuccess']) {
        this.loading = false;

        let info = data['data']['list'];
        this.cur_page = info['current_page'];
        this.page_size = Number(info['per_page']);
        this.total_num = info['total'];

        this.profit_data = [];

        info['data'].forEach(e => {
          let profit_rccode = new ProfitRecord();
          profit_rccode.time = e['date'];
          profit_rccode.transin_amount = e['deposit'];
          profit_rccode.transout_amount = e['withdrawal'];
          profit_rccode.bet_val = e['bet'];
          profit_rccode.prize_val = e['prize'];
          profit_rccode.profit = e['profits'];
          profit_rccode.bet_num = e['bet_num'];
          this.profit_data.push(profit_rccode);
        });

        let sum = data['data']['total'];

        this.total_bet = sum['bet'];
        this.total_bet_num = sum['bet_num'];
        this.total_deposit = sum['deposit'];
        this.total_prize = sum['prize'];
        this.total_withdrawal = sum['withdrawal'];
        this.total_profit = sum['profits'];

      } else {
        // 错误提示
        let error = data['data']['error'];
        this.modalService.error({
          nzTitle: '温馨提示',
          nzContent: error
        });
      };
    })
  }

  /**
   * 分页查询
   *
   * @param {*} e
   * @memberof RecordPageProfitComponent
   */
  public change_current_pagination_handler(e: any) {
    this.cur_page = Number(e);
    this.get_profit_list_handler();
  }

  /**
   * 当前页至1
   *
   * @memberof RecordPageProfitComponent
   */
  public set_current_page_handler() {
    this.cur_page = 1;
  }

  public onMonthChange(e) {
    console.log(e);
  }

  /**
   * 表格变化
   *
   * @param {*} e
   * @memberof RecordPageProfitComponent
   */
  public tableChange(e) {
    this.selectIndex = e;
  }

  /**
   * 获取线路数据
   *
   * @memberof RecordPageProfitComponent
   */
  public get_line_list_handler() {
    this.loadingLine = true;
    let date = Utils.formate_time(this.date, 'yyyy-MM') + '-01';

    this.api.get_line_month_list(this.token, date).subscribe(data => {
      if (data && data['isSuccess']) {
        this.loadingLine = false;
        this.line_data = [];

        data['data'].forEach(d => {
          let liList = new LineList();
          liList.line_name = d['line_name'];
          liList.sum_deposit = d['sum_deposit'];
          liList.sum_withdrawal = d['sum_withdrawal'];
          liList.sum_bet = d['sum_bet'];
          liList.sum_prize = d['sum_prize'];
          liList.sum_profit = d['sum_profit'];
          this.line_data.push(liList);
        });

      } else {
        // 错误提示
        let error = data['data']['error'];
        this.modalService.error({
          nzTitle: '温馨提示',
          nzContent: error
        });
      };
    });
  }
}

class ProfitRecord {
  public time: string; // 时间
  public transin_amount: string; // 转入金额
  public transout_amount: string; // 转出金额
  public bet_val: string; // 投注总额
  public prize_val: string; // 派奖总额
  public profit: string; // 总盈亏 
  public bet_num: string; // 投注金额 
}

class LineList {
  public line_name: string;
  public sum_deposit: string;
  public sum_withdrawal: string;
  public sum_bet: string;
  public sum_prize: string;
  public sum_profit: string;
}
