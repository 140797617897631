import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';
import { Utils } from 'src/app/common/util';
import { NzMessageService } from 'ng-zorro-antd';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  // 域名 （shengtiantest）
  private domain_arr = location.hostname.split('.');
  private detail_arr = this.domain_arr.slice(1, this.domain_arr.length - 1);
  public domain_name = `https://api.${this.detail_arr.join('.')}.${this.domain_arr[(this.domain_arr.length - 1)]}`;
  // private domain_name = `https://api.shengtian01.com`; //正式站
  // public domain_name = `https://api.n1.shengtiantest.com`; // 测试站
  private accept_str = 'application/vnd.zhanguo.merchant-v1+json';


  // 登录
  private api_url_login_requset = `${this.domain_name}/users/login`;
  // 帐变记录
  private api_url_transaction_record = `${this.domain_name}/transaction/list`;
  // 获取游戏类型
  private api_url_games_type = `${this.domain_name}/games/games-type`;
  // 获取帐变类型
  private api_url_transaction_type = `${this.domain_name}/transaction/type`;
  // 帐变详情
  private api_url_transaction_detail = `${this.domain_name}/transaction/detail`;

  // 投注报表
  private api_url_bet_list = `${this.domain_name}/bet/list`;
  // 下载投注报表
  private api_url_bet_list_excel = `${this.domain_name}/export-csv/bet`;
  // 注单详情
  private api_url_bet_detail_info = `${this.domain_name}/bet/detail`;

  // 追号报表
  private api_url_trace_list = `${this.domain_name}/trace/list`;
  private api_url_trace_detail_info = `${this.domain_name}/trace/detail`;

  // 盈亏报表
  private api_url_profit_list = `${this.domain_name}/reports/profit-daily`;
  // 线路报表
  private api_url_line_list = `${this.domain_name}/reports/line-month`;

  // 菜单栏
  private api_url_menu_list = `${this.domain_name}/menu/left`;
  // 自主彩赔率
  private api_url_lottery_odds = `${this.domain_name}/jackpot/list`;
  // 自主彩配置
  private api_url_set_jackpot = `${this.domain_name}/jackpot/set`;
  // 游戏配置列表
  private api_url_game_list = `${this.domain_name}/games/games-list`;
  // 游戏配置
  private api_url_game_config = `${this.domain_name}/games/game-open-set`;

  constructor(
    public http: HttpClient,
    public message: NzMessageService
  ) { }


  /**
   * 登录
   *
   * @param {string} username
   * @param {string} password
   * @returns {Observable<string[]>}
   * @memberof ApiService
   */
  get_login_info(username: string, password: string): Observable<string[]> {
    let headers = new HttpHeaders({ 'Accept': this.accept_str });
    return this.postUrlReturn(this.api_url_login_requset, { 'username': username, 'password': password }, { headers });
  }

  /**
   * 获取帐变记录
   *
   * @param {string} token
   * @returns {Observable<string[]>}
   * @memberof ApiService
   */
  get_transaction_record(token: string, page: number, page_size: number, start_time?: string, end_time?: string, transaction_code?: string, transaction_type?: any, game?: string, min_amount?: number, max_amount?: number, user_name?: string, ip?: string, coefficient?: string, is_tester?: string): Observable<string[]> {
    let token_str = `Bearer${token}`;
    let headers = new HttpHeaders({ 'Accept': this.accept_str, 'Authorization': token_str });
    let query_parameter_init = this.query_parameter_init(start_time, end_time, transaction_code, transaction_type, game, min_amount, max_amount, user_name, ip, coefficient, is_tester);
    let url = `${this.api_url_transaction_record}?page=${page}&page_size=${page_size}${query_parameter_init.start}${query_parameter_init.end}${query_parameter_init.transaction_code_str}${query_parameter_init.transaction_type_str}
    ${query_parameter_init.game_str}${query_parameter_init.min_amount_str}${query_parameter_init.max_amount_str}${query_parameter_init.coefficient_str}
    ${query_parameter_init.ip_str}${query_parameter_init.is_tester_str}${query_parameter_init.user_name_str}`;
    return this.getUrlReturn(url, { headers });
  }

  /**
   * 帐变详情
   *
   * @memberof ApiService
   */
  get_transaction_detail_info(token: string, id: number) {
    let token_str = `Bearer${token}`;
    let headers = new HttpHeaders({ 'Accept': this.accept_str, 'Authorization': token_str });
    let url = `${this.api_url_transaction_detail}/${id}`;
    return this.getUrlReturn(url, { headers });
  }

  /**
   * 投注报表
   *
   * @memberof ApiService
   */
  get_bet_list_info(token: string, page: number, page_size: number, start_time?: string, end_time?: string, bet_code?: string, user_name?: string, is_tester?: string, status?: string, issue?: string, game?: string, play?: string, coefficient?: string, bet_source?: string) {
    let token_str = `Bearer${token}`;
    let headers = new HttpHeaders({ 'Accept': this.accept_str, 'Authorization': token_str });
    let query_parameter_init = this.bet_parameter_init(start_time, end_time, bet_code, user_name, is_tester, status, issue, game, play, coefficient, bet_source);
    let url = `${this.api_url_bet_list}?page=${page}&page_size=${page_size}${query_parameter_init.start}${query_parameter_init.end}${query_parameter_init.bet_code_str}${query_parameter_init.user_name_str}${query_parameter_init.is_tester_str}${query_parameter_init.bet_statue_str}${query_parameter_init.issue_str}${query_parameter_init.game_str}${query_parameter_init.play_str}${query_parameter_init.coefficient_str}${query_parameter_init.bet_source_str}`;

    return this.getUrlReturn(url, { headers });
  }

  /**
   * 下载投注报表
   *
   * @param {string} token
   * @param {number} page
   * @param {number} page_size
   * @param {string} [start_time]
   * @param {string} [end_time]
   * @param {string} [bet_code]
   * @param {string} [user_name]
   * @param {string} [is_tester]
   * @param {string} [status]
   * @param {string} [issue]
   * @param {string} [game]
   * @param {string} [play]
   * @param {string} [coefficient]
   * @param {string} [bet_source]
   * @returns
   * @memberof ApiService
   */
  get_bet_list_excel(token: string, page: number, page_size: number, start_time?: string, end_time?: string, bet_code?: string, user_name?: string, is_tester?: string, status?: string, issue?: string, game?: string, play?: string, coefficient?: string, bet_source?: string) {
    let token_str = `Bearer${token}`;
    let headers = new HttpHeaders({ 'Accept': this.accept_str, 'Authorization': token_str });
    let query_parameter_init = this.bet_parameter_init(start_time, end_time, bet_code, user_name, is_tester, status, issue, game, play, coefficient, bet_source);
    let url = `${this.api_url_bet_list_excel}?page=${page}&page_size=${page_size}${query_parameter_init.start}${query_parameter_init.end}${query_parameter_init.bet_code_str}${query_parameter_init.user_name_str}${query_parameter_init.is_tester_str}${query_parameter_init.bet_statue_str}${query_parameter_init.issue_str}${query_parameter_init.game_str}${query_parameter_init.play_str}${query_parameter_init.coefficient_str}${query_parameter_init.bet_source_str}`;

    return this.getUrlReturn(url, { headers });
  }

  /**
   *  注单详情
   *
   *
   * @memberof ApiService
   */
  get_bet_detail_info(token: string, id: number) {
    let token_str = `Bearer${token}`;
    let headers = new HttpHeaders({ 'Accept': this.accept_str, 'Authorization': token_str });
    let url = `${this.api_url_bet_detail_info}/${id}`;
    return this.getUrlReturn(url, { headers });
  }

  /**
   *  追号报表
   *
   * @memberof ApiService
   */
  get_trace_list_info(token: string, page: number, page_size: number, start_time?: string, end_time?: string, trace_code?: string, user_name?: string, is_tester?: string, trace_status?: string, issue?: string, game?: string, play?: string, bet_model?: string, bet_source?: string) {
    let token_str = `Bearer${token}`;
    let headers = new HttpHeaders({ 'Accept': this.accept_str, 'Authorization': token_str });
    let query_parameter_init = this.query_parameter_init(start_time, end_time);

    let trace_code_str = '';
    if (trace_code !== undefined) {
      trace_code_str = `&trace_code=${trace_code}`;
    }
    let user_name_str = '';
    if (user_name !== undefined) {
      user_name_str = `&username=${user_name}`;
    }
    let is_tester_str = '';
    if (is_tester !== undefined && is_tester !== 'all') {
      is_tester_str = `&is_tester=${is_tester}`;
    }
    let trace_status_str = '';
    if (trace_status !== undefined && trace_status !== 'all') {
      trace_status_str = `&status=${trace_status}`;
    }
    let issue_str = '';
    if (issue !== undefined) {
      issue_str = `&issue=${issue}`;
    }
    let game_str = '';
    if (game !== undefined && game !== 'all') {
      game_str = `&game=${game}`;
    }
    let play_str = '';
    if (play !== undefined && play !== 'all') {
      play_str = `&play=${play}`;
    }
    let bet_model_str = '';
    if (bet_model !== undefined && bet_model !== 'all') {
      bet_model_str = `&coefficient=${bet_model}`;
    }
    let bet_source_str = '';
    if (bet_source !== undefined && bet_source !== 'all') {
      bet_source_str = `&bet_source=${bet_source}`;
    }

    let url = `${this.api_url_trace_list}?page=${page}&page_size=${page_size}${query_parameter_init.start}${query_parameter_init.end}${trace_code_str}${user_name_str}${is_tester_str}${trace_status_str}${issue_str}${game_str}${play_str}${bet_model_str}${bet_source_str}`;

    return this.getUrlReturn(url, { headers });
  }

  /**
   *  追号详情
   *
   * @memberof ApiService
   */
  get_trace_detail_info(token: string, id: number) {
    let token_str = `Bearer${token}`;
    let headers = new HttpHeaders({ 'Accept': this.accept_str, 'Authorization': token_str });
    let url = `${this.api_url_trace_detail_info}/${id}`;
    return this.getUrlReturn(url, { headers });
  }

  /**
   *  盈亏报表
   *
   * @memberof ApiService
   */
  get_daily_profit_list(token: string, page: number, page_size: number, start_time?: string, end_time?: string) {
    let token_str = `Bearer${token}`;
    let headers = new HttpHeaders({ 'Accept': this.accept_str, 'Authorization': token_str });
    let url = `${this.api_url_profit_list}?page=${page}&page_size=${page_size}&start_time=${start_time}&end_time=${end_time}`;

    return this.getUrlReturn(url, { headers });
  }

  /**
   * 获取线路报表
   *
   * @param {string} token
   * @param {string} date
   * @returns
   * @memberof ApiService
   */
  public get_line_month_list(token: string, date: string) {
    let token_str = `Bearer${token}`;
    let headers = new HttpHeaders({ 'Accept': this.accept_str, 'Authorization': token_str });
    let url = `${this.api_url_line_list}?date=${date}`;

    return this.getUrlReturn(url, { headers });
  }

  /**
   * 获取帐变类型
   *
   * @memberof ApiService
   */
  get_transaction_type_info(token: string) {
    let token_str = `Bearer${token}`;
    let headers = new HttpHeaders({ 'Accept': this.accept_str, 'Authorization': token_str });
    return this.getUrlReturn(this.api_url_transaction_type, { headers });
  }

  /**
   * 获取游戏类型
   *
   * @param {string} token
   * @returns
   * @memberof ApiService
   */
  get_game_type_info(token: string) {
    let token_str = `Bearer${token}`;
    let headers = new HttpHeaders({ 'Accept': this.accept_str, 'Authorization': token_str });
    return this.getUrlReturn(this.api_url_games_type, { headers });
  }

  /**
   * 初始化参数
   *
   * @private
   * @param {string} [start_time]
   * @param {string} [end_time]
   * @param {string} [user_name]
   * @param {string} [game_id]
   * @param {string} [way_id]
   * @param {string} [bet_code]
   * @param {string} [issue]
   * @returns
   * @memberof ApiService
   */
  private query_parameter_init(start_time?: string, end_time?: string, transaction_code?: string, transaction_type?: any, game?: string, min_amount?: number, max_amount?: number, user_name?: string, ip?: string, coefficient?: string, is_tester?: string) {
    let start_time_str = '';
    if (start_time !== undefined) {
      start_time_str = `&start_time=${start_time}`;
    }
    let end_time_str = '';
    if (end_time !== undefined) {
      end_time_str = `&end_time=${end_time}`;
    }
    let user_name_str = '';
    if (user_name !== undefined) {
      user_name_str = `&username=${user_name}`;
    }
    let transaction_type_str = '';
    if (transaction_type !== undefined) {
      transaction_type_str = `&transaction_type=${transaction_type}`;
    }
    let transaction_code_str = '';
    if (transaction_code !== undefined) {
      transaction_code_str = `&transaction_code=${transaction_code}`;
    }
    let game_str = '';
    if (game !== undefined && game !== 'all') {
      game_str = `&game=${game}`;
    }
    let min_amount_str = '';
    if (min_amount !== undefined) {
      min_amount_str = `&min_amount=${min_amount}`;
    }
    let max_amount_str = '';
    if (max_amount !== undefined) {
      max_amount_str = `&max_amount=${max_amount}`;
    }
    let ip_str = '';
    if (ip !== undefined) {
      ip_str = `&ip=${ip}`;
    }
    let coefficient_str = '';
    if (coefficient !== undefined && coefficient !== 'all') {
      coefficient_str = `&coefficient=${coefficient}`;
    }
    let is_tester_str = '';
    if (is_tester !== undefined && is_tester !== 'all') {
      is_tester_str = `&is_tester=${is_tester}`;
    }

    return {
      'start': start_time_str,
      'end': end_time_str,
      'user_name_str': user_name_str,
      'transaction_type_str': transaction_type_str,
      'transaction_code_str': transaction_code_str,
      'game_str': game_str,
      'min_amount_str': min_amount_str,
      'max_amount_str': max_amount_str,
      'ip_str': ip_str,
      'coefficient_str': coefficient_str,
      'is_tester_str': is_tester_str,
    };
  }

  /**
   * 投注报表参数
   *
   * @private
   * @param {string} [start_time]
   * @param {string} [end_time]
   * @param {string} [bet_code]
   * @param {string} [user_name]
   * @param {string} [is_tester]
   * @param {string} [bet_statue]
   * @param {string} [issue]
   * @param {string} [game]
   * @param {string} [play]
   * @param {string} [coefficient]
   * @param {string} [bet_source]
   * @returns
   * @memberof ApiService
   */
  private bet_parameter_init(start_time?: string, end_time?: string, bet_code?: string, user_name?: string, is_tester?: string, bet_statue?: string, issue?: string, game?: string, play?: string, coefficient?: string, bet_source?: string) {
    let start_time_str = '';
    if (start_time !== undefined) {
      start_time_str = `&start_time=${start_time}`;
    }
    let end_time_str = '';
    if (end_time !== undefined) {
      end_time_str = `&end_time=${end_time}`;
    }
    let bet_code_str = '';
    if (bet_code !== undefined) {
      bet_code_str = `&bet_code=${bet_code}`;
    }
    let user_name_str = '';
    if (user_name !== undefined) {
      user_name_str = `&username=${user_name}`;
    }
    let game_str = '';
    if (game !== undefined && game !== 'all') {
      game_str = `&game=${game}`;
    }
    let coefficient_str = '';
    if (coefficient !== undefined && coefficient !== 'all') {
      coefficient_str = `&coefficient=${coefficient}`;
    }
    let is_tester_str = '';
    if (is_tester !== undefined && is_tester !== 'all') {
      is_tester_str = `&is_tester=${is_tester}`;
    }
    let bet_statue_str = '';
    if (bet_statue !== undefined && bet_statue !== 'all') {
      bet_statue_str = `&status=${bet_statue}`;
    }
    let issue_str = '';
    if (issue !== undefined) {
      issue_str = `&issue=${issue}`;
    }
    let play_str = '';
    if (play !== undefined && play !== 'all') {
      play_str = `&play=${play}`;
    }
    let bet_source_str = '';
    if (bet_source !== undefined && bet_source !== 'all') {
      bet_source_str = `&bet_source=${bet_source}`;
    }

    return {
      'start': start_time_str,
      'end': end_time_str,
      'user_name_str': user_name_str,
      'bet_code_str': bet_code_str,
      'game_str': game_str,
      'coefficient_str': coefficient_str,
      'is_tester_str': is_tester_str,
      'bet_statue_str': bet_statue_str,
      'issue_str': issue_str,
      'play_str': play_str,
      'bet_source_str': bet_source_str
    }
  }

  /**
   *  获取左侧菜单栏
   *
   * @private
   * @memberof ApiService
   */
  get_menu_list_handler(token: string) {
    let token_str = `Bearer${token}`;
    let headers = new HttpHeaders({ 'Accept': this.accept_str, 'Authorization': token_str });
    return this.getUrlReturn(this.api_url_menu_list, { headers });
  }

  /**
   * 获取自主彩赔率
   *
   * @param {string} token
   * @memberof ApiService
   */
  get_lottery_odds_handler(token: string) {
    let token_str = `Bearer${token}`;
    let headers = new HttpHeaders({ 'Accept': this.accept_str, 'Authorization': token_str });
    return this.getUrlReturn(this.api_url_lottery_odds, { headers });
  }

  /**
   * 修改自主彩配置
   *
   * @param {string} token
   * @param {string} lottery_id
   * @param {string} [nickname]
   * @param {string} [profit_rate]
   * @param {string} [number_count]
   * @param {string} [max_amount]
   * @returns
   * @memberof ApiService
   */
  get_set_lottery_jackpot_handler(token: string, lottery_id: string, nickname?: string, profit_rate?: string, number_count?: string, max_amount?: string) {
    let token_str = `Bearer${token}`;
    let headers = new HttpHeaders({ 'Accept': this.accept_str, 'Authorization': token_str });
    let url = `${this.api_url_set_jackpot}?lottery_id=${lottery_id}&nickname=${nickname}&profit_rate=${profit_rate}&number_count=${number_count}&max_amount=${max_amount}`;

    return this.getUrlReturn(url, { headers });
  }

  /**
   * 获取游戏列表
   *
   * @param {string} token
   * @returns
   * @memberof ApiService
   */
  get_game_list_handler(token: string) {
    let token_str = `Bearer${token}`;
    let headers = new HttpHeaders({ 'Accept': this.accept_str, 'Authorization': token_str });
    return this.getUrlReturn(this.api_url_game_list, { headers });
  }

  /**
   *   配置游戏状态
   *
   * @param {string} token
   * @param {string} id
   * @param {number} open
   * @returns
   * @memberof ApiService
   */
  get_game_config_handler(token: string, id: string, open: number ) {
    let token_str = `Bearer${token}`;
    let headers = new HttpHeaders({ 'Accept': this.accept_str, 'Authorization': token_str });
    let url = `${this.api_url_game_config}?game_id=${id}&open=${open}`;

    return this.getUrlReturn(url, { headers });
  }

  /**
   * http-post请求，获取数据
   *
   * @private
   * @param {string} url
   * @param {Object} [data]
   * @param {Object} [options]
   * @returns {Observable<string[]>}
   * @memberof ApiService
   */
  // tslint:disable-next-line:ban-types
  private postUrlReturn(url: string, data?: Object, options?: Object): Observable<string[]> {
    return this.http.post<string[]>(url, data, options).pipe(
      map(res => res),
      tap(() => { }),
      catchError(this.handleError('error', []))
    );
  }

  /**
   * http-get请求，获取数据
   *
   * @private
   * @param {string} url
   * @param {Object} [options]
   * @returns {Observable<string[]>}
   * @memberof ApiService
   */
  // tslint:disable-next-line:ban-types
  private getUrlReturn(url: string, options?: Object): Observable<string[]> {
    return this.http.get<string[]>(url, options).pipe(
      map(res => res),
      tap(() => { }),
      catchError(this.handleError('error', []))
    );
  }

  /**
   * 错误信息处理
   *
   * @private
   * @template T
   * @param {string} [operation='operation']
   * @param {T} [result]
   * @returns
   * @memberof ApiService
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      this.show_tips(error);

      return of(result as T);
    };
  }

  private show_tips(error: any) {
    let error_code: number = Number(error['status']);

    switch (error_code) {
      case 401:
        this.message.create('warning', `登录超时，请您重新登录！`);
        break;
      case 429:
        this.message.create('warning', `服务器繁忙，请稍后重试！`);
        break;
      case 500:
        this.message.create('warning', `服务器异常！`);
        break;
      default:
        this.message.create('warning', `服务器异常哦！`);
        break;
    }
  }
}
