import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'transferType'
})
export class TransferTypePipe implements PipeTransform {

  transform(value: any): any {
    let transfer_type: string;

    switch (value) {
      case 1: transfer_type = '充值转入'; break;
      case 2: transfer_type = '提现转出'; break;
      case 5: transfer_type = '追号冻结'; break;
      case 6: transfer_type = '投注解冻'; break;
      case 7: transfer_type = '加入游戏'; break;
      case 8: transfer_type = '撤单返款'; break;
      case 9: transfer_type = '提现冻结'; break;
      case 10: transfer_type = '提现解冻'; break;
      case 11: transfer_type = '派发奖金'; break;
      case 12: transfer_type = '撤销派奖'; break;
      case 15: transfer_type = '追号返款'; break;
      case 16: transfer_type = '充值费用返还'; break;
      case 17: transfer_type = '提现手续费'; break;
      case 18: transfer_type = '人工充值'; break;
      case 19: transfer_type = '管理员提现'; break;
      case 20: transfer_type = '分红'; break;
      case 21: transfer_type = '撤销分红'; break;
      case 22: transfer_type = '理赔充值'; break;
      case 23: transfer_type = '促销派奖'; break;
      case 27: transfer_type = '违规加币'; break;
      case 28: transfer_type = '投注返点'; break;
      case 30: transfer_type = '促销派奖-彩票'; break;
      case 38: transfer_type = '虚拟币扣减'; break;
      case 39: transfer_type = '充值扣减'; break;
    };

    return transfer_type;
  }

}
